<template>
  <div>
    <label class="input-label" v-if="label">{{ label }}</label>
    <v-combobox
      v-model="input"
      :items="items"
      label=""
      :dense="dense"
      :return-object="false"
      item-value="value"
      :class="label ? 'mt-1' : ''"
      :search-input.sync="search"
      item-text="name"
      outlined
      @input="$emit('input', input)"
      v-bind="$attrs"
      multiple
      small-chips
      :rules="getRules"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          label
          small
          color="green lighten-3"
        >
          <span class="pr-2">
            {{ item }}
          </span>
          <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
        </v-chip>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching "<strong>{{ search }}</strong
              >". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import inputMixin from "@/mixins/input.mixin.js";
export default {
  mixins: [inputMixin],
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style></style>
