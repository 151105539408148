<template>
  <v-menu offset-y min-width="200px" style="z-index: 1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" tile icon dark v-bind="attrs" v-on="on">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <template v-if="authUser && authUser.display_name">
        <v-list-item>
          <v-list-item-avatar size="45">
            <v-avatar color="secondary">
              <v-img
                v-if="authUser.photo_url"
                :src="authUser.photo_url"
              ></v-img>
              <span v-else class="text-h5 text-uppercase">
                {{ getLogoName }}
              </span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ authUser.display_name }}</v-list-item-title>
            <v-list-item-subtitle v-if="authUser.email">{{
              authUser.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <v-list-item to="/" active-class="primary--text">
        <v-list-item-avatar size="18" tile>
          <v-icon size="18">mdi-compass-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Discover</v-list-item-title>
      </v-list-item>
      <v-list-item to="/people" active-class="primary--text">
        <v-list-item-avatar size="18" tile>
          <v-icon size="18">mdi-account-group</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>People</v-list-item-title>
      </v-list-item>
      <v-list-item to="/feed" active-class="primary--text">
        <v-list-item-avatar size="18" tile>
          <v-icon size="18">mdi-lightning-bolt</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Feed</v-list-item-title>
      </v-list-item>
      <template v-if="isAuth">
        <v-list-item :to="`/profile/${uid}`" active-class="primary--text">
          <v-list-item-avatar size="18" tile>
            <v-icon size="18">mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>My Profile</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout" active-class="primary--text">
          <v-list-item-avatar size="18" tile>
            <v-icon size="18">mdi-location-exit</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item to="/login" active-class="primary--text">
          <v-list-item-avatar size="18" tile>
            <v-icon size="18">mdi-login</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item>
        <v-list-item to="/signup" active-class="primary--text">
          <v-list-item-avatar size="18" tile>
            <v-icon size="18">mdi-account-plus-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Signup</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "@/utils/firebase.utils";
export default {
  computed: {
    getLogoName() {
      if (this.authUser && this.authUser.display_name) {
        return this.authUser.display_name.slice(0, 2);
      }
      return "A";
    },
  },
  methods: {
    logout() {
      signOut(auth);
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
