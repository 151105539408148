<template>
  <div>
    <div class="d-flex mt-3 justify-space-between align-center">
      <div class="subtitle-1 font-weight-medium">People around</div>
      <a
        class="subtitle-2 font-weight-medium blue--text text-decoration-underline"
        @click="$router.push('/people')"
        >Show All</a
      >
    </div>
    <v-slide-group
      multiple
      show-arrows
      :max="2"
      class="people-slide"
      style="height: 198px"
    >
      <v-slide-item v-for="(user, i) in users" :key="i">
        <v-card
          class="mr-3 my-1"
          width="160px"
          outlined
          rounded="lg"
          :to="`/profile/${user.id}`"
        >
          <v-card-text class="d-flex align-center flex-column">
            <v-avatar color="secondary">
              <v-img :src="getUserProfileUrl(user)"></v-img>
            </v-avatar>
            <div
              class="subtitle-2 mt-2 text-truncate text-center black--text text-capitalize"
              style="width: 100%; height: 22px"
            >
              {{ user.display_name }}
            </div>
            <div
              class="caption text-truncate text-center text-lowercase"
              style="width: 100%; height: 22px"
            >
              {{ user.Title || "" }}
            </div>
            <div
              class="caption text-truncate text-center text-lowercase"
              style="width: 100%; height: 20px"
            >
              {{ getHashTags(user) }}
            </div>
            <v-btn
              class="mt-2"
              v-if="user.isFollowing"
              color="primary"
              outlined
              rounded
              @click="unFollowUser(user)"
              :loading="user.loading"
              small
              >Following</v-btn
            >
            <v-btn
              @click="followUser(user)"
              class="primary--text mt-2"
              v-else
              text
              rounded
              :loading="user.loading"
              small
              depressed
              >Follow</v-btn
            >
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import peopleMixins from "@/mixins/people.mixins";

export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [peopleMixins],
  methods: {
    getHashTags({ interests, professionalskills }) {
      let list = [...(interests || []), ...(professionalskills || [])];
      return list
        .splice(0, 2)
        .map((i) => {
          if (i.startsWith("#")) return i;
          return `#${i}`;
        })
        .toString();
    },
  },
};
</script>

<style></style>
