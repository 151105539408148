<template>
  <div>
    <template v-for="(post, i) of posts">
      <post-view-card @onPost="onPost(i, $event)" :post="post" :key="i" />
      <v-divider :key="'divider-' + i"></v-divider>
    </template>
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
  </div>
</template>

<script>
import PostViewCard from "@/components/public/PostViewCard.vue";
import InfiniteLoading from "vue-infinite-loading";
import {
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { colPosts, colUsers } from "@/utils/firebase.utils";
export default {
  props: {
    userId: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { PostViewCard, InfiniteLoading },
  data() {
    return {
      loading: true,
      posts: [],
      users: {},
      lastPost: null,
    };
  },
  methods: {
    onPost(index, post) {
      post = { ...this.posts[index], ...post };
      this.$set(this.posts, index, post);
    },
    async infiniteHandler($state) {
      const vm = this;
      try {
        let userRef = doc(colUsers, vm.userId);
        let conditions = [
          orderBy("timeanddateofpost", "desc"),
          where("userref", "==", userRef),
        ];
        if (vm.lastPost) {
          conditions.push(startAfter(vm.lastPost));
        }
        conditions.push(limit(10));
        let postQuery = query(colPosts, ...conditions);
        let docs = (await getDocs(postQuery)).docs;
        let posts = docs.map((i) => {
          return { ...i.data(), id: i.id, user: vm.user };
        });
        vm.posts = vm.posts.concat(posts);

        vm.lastPost = docs[docs.length - 1];
        if (docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.$alert.show(error.message);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.post-view {
  max-width: 100p;
}
</style>
