<template>
  <div>
    <div class="d-flex mt-5 justify-space-between align-center">
      <div class="subtitle-1 font-weight-medium">{{ title }}</div>
      <a
        class="subtitle-2 font-weight-medium blue--text text-decoration-underline"
        @click="openAll()"
        >Show All</a
      >
    </div>

    <v-slide-group
      multiple
      show-arrows
      :max="2"
      class="people-slide"
      style="height: 128px"
    >
      <v-slide-item v-for="(item, i) in list" :key="i">
        <v-card class="mr-3 my-1" width="160px" outlined rounded="lg">
          <v-card-text class="d-flex align-center flex-column">
            <div
              class="subtitle-2 mt-2 text-truncate text-lowercase text-center black--text"
              style="width: 100%"
            >
              {{ item.name }}
            </div>
            <div class="caption text-truncate text-center" style="width: 100%">
              {{ item.value }} People
            </div>
            <v-btn
              class="mt-2"
              color="primary"
              text
              rounded
              small
              depressed
              @click="openAsk(item.facet)"
              >Ask</v-btn
            >
          </v-card-text>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import { mapFacets } from "@/utils/common.utils";
export default {
  props: {
    title: {
      type: String,
      default: "Find by Interest",
    },
    items: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    list() {
      return mapFacets(this.items);
    },
  },
  methods: {
    openAsk(tag) {
      const vm = this;
      let path = `/post?hashtag=${tag}`;
      if (!vm.confirmLogin(path)) return;
      this.$router.push(path);
    },
    openAll() {
      if (this.title == "Find by Interest") {
        this.$router.push("/interests");
      } else {
        this.$router.push("/skills");
      }
    },
  },
};
</script>

<style></style>
