import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";
import AlertModal from "./components/ui/alert";
import ConfirmModel from "./components/ui/confirm";

import vuetify from "./plugins/vuetify";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import commonMixins from "./mixins/common.mixins";
import DatetimePicker from "vuetify-datetime-picker";
import SnackBar from "./components/ui/snackbar";
import ReadMore from "vue-read-more";
import { MAP_API_KEY } from "./assets/constants/config.constants";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.use(AlertModal);
Vue.mixin(commonMixins);
Vue.use(DatetimePicker);
Vue.use(ConfirmModel);
Vue.use(ReadMore);
Vue.use(SnackBar);

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: MAP_API_KEY,
  installComponents: true,
  vueGoogleMapsCompatibility: false,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
