<template>
  <v-card flat>
    <v-card-text v-if="!showSignup">
      <v-btn
        outlined
        block
        class="mb-4"
        large
        :loading="isGoogleLoading"
        @click="signInWithGoogle()"
      >
        <v-img
          src="../../assets/img/google.svg"
          class="mr-1"
          max-width="28"
          max-height="28"
        />

        Continue with Google
      </v-btn>
      <v-btn
        outlined
        block
        class="mb-4"
        large
        :loading="isFacebookLoading"
        @click="signInWithFacebook()"
      >
        <v-img
          src="../../assets/img/facebook.svg"
          class="mr-1"
          max-width="28"
          max-height="28"
        />

        Continue with Facebook
      </v-btn>
      <v-btn outlined block class="mb-4" @click="signInWithOutFacebook()" large>
        <v-img
          src="../../assets/img/mail.png"
          class="mr-1"
          max-width="28"
          max-height="28"
        />
        Continue with Email
      </v-btn>
    </v-card-text>
    <v-card-text v-else>
      <v-form v-model="isValid" @submit.prevent="submitRegister()" ref="form">
        <input-field
          label="Name"
          class="mb-1"
          rules="required"
          v-model="form.display_name"
        ></input-field>

        <input-field
          v-model="form.email"
          label="Email"
          rules="email"
        ></input-field>
        <input-field
          v-model="form.username"
          label="Username"
          class="mb-1"
          rules="required"
        ></input-field>
        <input-field
          v-model="form.Title"
          label="Title"
          class="mb-1"
          rules="required"
        ></input-field>
        <address-field
          label="Your Location"
          class="mb-1"
          @onAddress="form.residential = $event"
        ></address-field>
        <combo-box label="Interests" v-model="form.interests"></combo-box>
        <combo-box label="Skills" v-model="form.professionalskills"></combo-box>
        <v-btn
          class="primary text-none"
          depressed
          block
          type="submit"
          large
          :loading="loading"
          >Complete Sign Up</v-btn
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { auth, colUsers, db } from "@/utils/firebase.utils";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import rulesConstants from "@/assets/constants/rules.constants";
import AddressField from "@/components/ui/form/AddressField.vue";
import ComboBox from "@/components/ui/form/ComboBox.vue";
import InputField from "@/components/ui/form/InputField.vue";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  linkWithPopup,
  unlink,
} from "firebase/auth";
import axios from "axios";
export default {
  components: {
    AddressField,
    ComboBox,
    InputField,
  },
  data() {
    return {
      showSignup: true,
      isGoogleLoading: false,
      isFacebookLoading: false,
      loading: false,
      rules: [rulesConstants.required],
      emailRules: [rulesConstants.required, rulesConstants.email],
      isValid: false,
      form: {
        display_name: "",
        email: "",
        username: "",
        Title: "",
        interests: [],
        professionalskills: [],
        residential: null,
        dob: "",
        gender: "",
      },
    };
  },
  methods: {
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      provider.addScope("https://www.googleapis.com/auth/user.birthday.read");
      provider.addScope("https://www.googleapis.com/auth/user.gender.read");
      // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      const vm = this;
      try {
        vm.isGoogleLoading = true;
        let googleAuth = auth.currentUser.providerData.find(
          (i) => i.providerId == "google.com"
        );
        if (googleAuth) {
          await unlink(auth.currentUser, "google.com");
        }
        let user = await linkWithPopup(auth.currentUser, provider);
        let { oauthAccessToken, displayName, email, photoUrl } =
          user._tokenResponse;
        vm.form.photo_url = photoUrl;
        vm.form.display_name = displayName;
        vm.form.email = email;
        try {
          let peopleRes = await axios.get(
            "https://people.googleapis.com/v1/people/me",
            {
              params: {
                personFields: "genders,birthdays",
              },
              headers: {
                Authorization: `Bearer ${oauthAccessToken}`,
              },
            }
          );
          let birthday = peopleRes.data.birthdays[0];
          let gender = peopleRes.data.genders[0];
          if (birthday && birthday.date) {
            vm.form.dob = `${birthday.date.day}/${birthday.date.month}/${birthday.date.year}`;
          }
          if (gender) {
            vm.form.gender = gender.value || "";
          }
        } catch (error) {
          console.log(error);
        }
        vm.form.username = await vm.generateUniqueUsername(
          vm.form.display_name
        );
        vm.isGoogleLoading = false;
        vm.showSignup = true;
      } catch (error) {
        vm.isGoogleLoading = false;
        vm.handleError(error);
      }
    },
    async signInWithFacebook() {
      const provider = new FacebookAuthProvider();
      const vm = this;
      try {
        vm.isFacebookLoading = true;
        let facebookAuth = auth.currentUser.providerData.find(
          (i) => i.providerId == "facebook.com"
        );
        if (facebookAuth) {
          await unlink(auth.currentUser, "facebook.com");
        }
        let user = await linkWithPopup(auth.currentUser, provider);
        let { displayName, email, photoUrl } = user._tokenResponse;
        vm.form.photo_url = photoUrl;
        vm.form.display_name = displayName;
        vm.form.email = email;
        vm.form.username = await vm.generateUniqueUsername(
          vm.form.display_name
        );
        vm.isFacebookLoading = false;
        vm.showSignup = true;
      } catch (error) {
        vm.isFacebookLoading = false;
        vm.handleError(error);
      }
    },
    async generateUniqueUsername(name) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          if (!name) {
            resolve("");
            return;
          }
          const cleanedName = name.replace(/[^a-zA-Z0-9]/g, ".");
          let suffix = "";
          let isUnique = false;
          let generatedUsername = "";
          let attempts = 0;
          while (!isUnique && attempts < 10) {
            generatedUsername = (cleanedName + suffix).toLowerCase();
            let q = query(colUsers, where("username", "==", generatedUsername));
            const snapshot = await getDocs(q);
            if (snapshot.empty) {
              isUnique = true;
            }
            suffix = suffix === "" ? "1" : (parseInt(suffix) + 1).toString();
            attempts++;
          }
          resolve(generatedUsername);
        } catch (error) {
          reject(error);
        }
      });
    },
    async submitRegister() {
      const vm = this;
      vm.$refs.form.validate();
      if (!vm.isValid) return;
      if (vm.form.residential == null) {
        vm.$alert.show("Please select your location.");
        return;
      }
      try {
        vm.loading = true;
        let username = vm.form.username.trim().toLowerCase();
        let q = query(colUsers, where("username", "==", username));
        let size = (await getDocs(q)).size;
        if (size > 0) {
          vm.loading = false;
          vm.$alert.show(
            "Username already exists. Please use a different username"
          );
          return;
        }
        let data = { ...vm.form, created_time: new Date(), web_signup: true };
        data.phone_number = `${auth.currentUser.phoneNumber}`;
        let uid = auth.currentUser.uid;
        let batch = writeBatch(db);
        let userRef = doc(colUsers, uid);
        batch.set(userRef, data);
        vm.form.interests.forEach((skillname) => {
          let ref = collection(userRef, "professionalskills");
          batch.set(doc(ref), {
            skillname,
            thumbs_up_count: 0,
          });
        });
        vm.form.interests.forEach((interestname) => {
          let ref = collection(userRef, "interests");
          batch.set(doc(ref), {
            interestname,
            thumbs_up_count: 0,
          });
        });
        await batch.commit();
        vm.redirect();
        vm.loading = false;
      } catch (e) {
        vm.loading = false;
        vm.$alert.show(e.message);
      }
    },
    redirect() {
      let { redirect } = this.$route.query;
      this.$router.replace(redirect || "/");
    },
  },
};
</script>

<style></style>
