<template>
  <div class="people-slide">
    <v-skeleton-loader v-if="loading" type="list-item-three-line" />
    <template v-else>
      <div v-if="tags.length">
        <v-chip-group
          class="flex-grow-1"
          mandatory
          active-class="primary"
          v-model="form.tag"
          @change="submit()"
        >
          <v-chip class="my-0" value="all" small :outlined="form.tag != 'all'">
            All
          </v-chip>
          <v-chip
            v-for="(item, i) in tags"
            :value="item.value"
            class="my-0"
            :key="i"
            small
            :outlined="item.value != form.tag"
          >
            <span v-if="isContest(item)">
              <svg height="10" width="15" class="blinking">
                <circle cx="5" cy="5" r="5" fill="red" />
              </svg>
            </span>
            {{ item.name }}
          </v-chip>
        </v-chip-group>
      </div>
      <div v-if="hashtags.length">
        <v-chip-group
          mandatory
          active-class="red--text"
          v-model="form.hashtags"
          @change="submit()"
        >
          <v-chip
            value="all"
            class="my-0 mr-0 white text-decoration-underline"
            small
            :class="form.hashtags == 'all' ? 'red--text' : 'primary--text'"
          >
            All
          </v-chip>
          <v-chip
            class="my-0 mr-0 white text-decoration-underline"
            :class="form.hashtags == item.value ? 'red--text' : 'primary--text'"
            v-for="(item, i) in hashtags"
            :value="item.value"
            :key="i"
            small
          >
            {{ item.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <v-text-field
        prepend-inner-icon="mdi-magnify"
        outlined
        class="my-3"
        type="search"
        v-if="false"
        @blur="submit()"
        hide-details
        @keyup.enter="submit()"
        dense
        v-model="form.search"
        clearable
        placeholder="Search"
      />
      <div>
        <v-btn-toggle
          v-model="form.sort"
          tile
          style="width: calc(100% - 8px)"
          color="green"
          group
          @change="submit"
          dense
        >
          <v-btn width="50%" value="latest" class="text-none ml-0">
            Latest First
          </v-btn>
          <v-btn width="50%" value="top" class="text-none mr-0">
            Top First
          </v-btn>
        </v-btn-toggle>
      </div>
    </template>
  </div>
</template>

<script>
import { fetchAlgoliaFacets } from "@/utils/algolia.utils";
import { mapFacetSelect } from "@/utils/common.utils";
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      hashtags: [],
      loading: false,
      tags: [],
      form: {
        hashtags: "all",
        sort: "latest",
        tag: "all",
      },
    };
  },
  methods: {
    submit() {
      this.$emit("onFilter", this.form);
      this.dialog = false;
    },
    isContest(item) {
      return item.value?.toLowerCase() == "contest";
    },
    async fetchFacets() {
      const vm = this;
      try {
        vm.loading = true;
        let requestBody = {
          page: 0,
          hitsPerPage: 0,
          query: "*",
          index: "userposts",
          aroundLatLng: vm.currentLocation.latlong,
          aroundRadius: "25000",
          facets: ["hashtags", "tag"],
        };
        let facetRes = await fetchAlgoliaFacets(requestBody);
        let { hashtags, tag } = facetRes.facets;
        hashtags = mapFacetSelect(hashtags || {});
        let tags = mapFacetSelect(tag || {});
        vm.tags = vm.bringFront(tags, "contest");
        vm.hashtags = vm.bringFront(hashtags, "#talentofvasaivirar");

        vm.loading = false;
      } catch (error) {
        vm.loading = false;
        console.error(error);
      }
    },
    bringFront(items, name) {
      let contestTag = items.find((i) => {
        return i.value?.trim().toLowerCase() == name;
      });
      if (contestTag) {
        let index = items.findIndex(
          (i) => i.value?.trim().toLowerCase() == name
        );
        items.splice(index, 1);
        items.unshift(contestTag);
      }
      return items;
    },
  },
  mounted() {
    this.fetchFacets();
  },
  beforeMount() {
    this.form = this.value;
  },
};
</script>

<style>
.blinking {
  -webkit-animation: 2s blink ease infinite;
  -moz-animation: 2s blink ease infinite;
  -ms-animation: 2s blink ease infinite;
  -o-animation: 2s blink ease infinite;
  animation: 2s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
