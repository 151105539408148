<template>
  <app-layout title="Discover">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar-three-line, image, article"
    ></v-skeleton-loader>
    <template v-else>
      <people-around-list :users="peoples" />
      <div
        class="text-md-h5 text-subtitle-1 my-2 d-flex align-center justify-between"
        v-if="!isAuth"
      >
        Get listed, get found!
        <v-btn depressed small class="primary" to="/signup">Join Now</v-btn>
      </div>
      <talent-of-vasai-virar-info></talent-of-vasai-virar-info>
      <latest-posts-list :posts="posts" />
      <by-interest-list :items="interests" />
      <by-interest-list title="Find by Pro Skills" :items="skills" />
      <public-footer></public-footer>
    </template>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import PeopleAroundList from "@/components/public/PeopleAroundList.vue";
import ByInterestList from "@/components/public/ByInterestList.vue";
import LatestPostsList from "@/components/public/LatestPostsList.vue";
import {
  fetchAlgoliaFacets,
  fetchAlgoliaPosts,
  fetchAlgoliaUsers,
} from "@/utils/algolia.utils";
import { mapDocs } from "@/utils/common.utils";
import TalentOfVasaiVirarInfo from "@/components/public/TalentOfVasaiVirarInfo.vue";
import PublicFooter from "@/components/public/PublicFooter.vue";
export default {
  components: {
    AppLayout,
    PeopleAroundList,
    ByInterestList,
    LatestPostsList,
    TalentOfVasaiVirarInfo,
    PublicFooter,
  },
  data() {
    return {
      people: [],
      interests: {},
      skills: {},
      posts: [],
      users: {},
      loading: true,
    };
  },
  watch: {
    currentLocation: {
      handler() {
        console.log("current location updated...");
        this.fetchDetails();
      },
      deep: true,
    },
  },
  methods: {
    async fetchDetails() {
      const vm = this;
      try {
        vm.loading = true;
        let requestBody = {
          page: 0,
          hitsPerPage: 10,
          query: "*",
          index: "users",
          aroundLatLng: vm.currentLocation.latlong,
          aroundRadius: "25000",
        };
        let facetRequestBody = {
          ...requestBody,
          facets: ["professionalskills", "interests"],
        };
        let peopleRes = await fetchAlgoliaUsers({
          ...requestBody,
        });
        let postRes = await fetchAlgoliaPosts({
          ...requestBody,
          index: "userposts",
        });
        let facetRes = await fetchAlgoliaFacets({
          ...facetRequestBody,
        });
        let { interests, professionalskills } = facetRes.facets;
        vm.interests = interests;
        vm.skills = professionalskills;
        let peoples = mapDocs(peopleRes.docs).map((i) => {
          i.loading = false;
          return i;
        });
        vm.peoples = await vm.getUsersFollow(peoples);
        let posts = mapDocs(postRes.docs);
        await vm.getPostUsers(posts);
        vm.loading = false;
      } catch (error) {
        vm.handleError(error);
      }
    },
  },
  mounted() {
    this.fetchDetails();
  },
};
</script>

<style></style>
