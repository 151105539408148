<template>
  <app-layout :title="title">
    <please-wait v-if="isAuthLoading"></please-wait>
    <div class="invite-content" v-else>
      <img class="mx-auto py-10" src="../assets/img/logo.png" width="150" />
      <template v-if="!isLoggedIn">
        <v-card v-show="!isOtpSend" flat>
          <v-card-text class="pt-0">
            <v-text-field
              :show-feedback="false"
              v-model="phone"
              placeholder="Phone Number"
              outlined
              dense
            />
            <div id="recaptcha-container"></div>
            <v-btn
              :loading="loading"
              depressed
              block
              class="primary text-none"
              @click="sendOTP()"
              >Submit</v-btn
            >
            <div class="text-center my-5" v-if="isSignup">
              Already have an account?
              <a class="text-decoration-underline" @click="openRoute('login')"
                >Login</a
              >
            </div>
            <div class="text-center my-5" v-else>
              Not a member?
              <a class="text-decoration-underline" @click="openRoute('signup')"
                >Create Account</a
              >
            </div>
          </v-card-text>
        </v-card>
        <v-card v-show="isOtpSend" flat>
          <v-card-title> Verify your phone number </v-card-title>
          <v-card-text>
            <p class="input-label--medium text-black mt-0">
              Enter the 6-digit code we sent to
              <a
                style="cursor: pointer"
                @click="isOtpSend = false"
                class="primary--text"
                >+91{{ phone }}</a
              >
            </p>
            <v-otp-input
              type="number"
              length="6"
              class="mb-4"
              v-model="otp"
            ></v-otp-input>
            <v-btn
              class="primary text-none"
              depressed
              block
              :loading="loading"
              @click="submitOTP()"
              >Verify</v-btn
            >
          </v-card-text>
        </v-card>
      </template>
      <complete-signup v-else-if="isNotSignedUp" />
    </div>
  </app-layout>
</template>
<script>
import inviteMixins from "@/mixins/invite.mixins";
import {
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth, colUsers } from "@/utils/firebase.utils";
import { doc, getDoc } from "firebase/firestore";
import PleaseWait from "@/components/ui/PleaseWait.vue";
import AppLayout from "@/components/ui/AppLayout.vue";
import CompleteSignup from "@/components/account/CompleteSignup.vue";
export default {
  components: {
    PleaseWait,
    AppLayout,
    CompleteSignup,
  },
  data() {
    return {
      isOtpSend: false,
      isAuthLoading: true,
      phone: "",
      loading: false,
      isNotSignedUp: false,
      isLoggedIn: false,
      otp: "",
    };
  },
  computed: {
    isSignup() {
      return this.$route.name == "signup";
    },
    title() {
      if (this.isNotSignedUp) return "Sign Up";
      return this.isSignup ? "Sign Up" : "Login";
    },
  },
  mixins: [inviteMixins],
  methods: {
    openRoute(name) {
      let query = this.$route.query;
      this.$router.push({
        name,
        query,
      });
    },
    async submitOTP() {
      const vm = this;
      if (vm.otp.length != 6) {
        vm.$alert.show("Please enter 6 digit otp");
        return;
      }
      let result = window.confirmationResult;
      try {
        vm.loading = true;
        let { user } = await result.confirm(vm.otp);
        let userDoc = (await getDoc(doc(colUsers, user.uid))).data();
        if (userDoc == null) {
          vm.isLoggedIn = true;
          vm.isNotSignedUp = true;
        } else {
          vm.redirect();
          console.log("User already exists...");
        }
        vm.loading = false;
      } catch (e) {
        vm.loading = false;
        vm.$alert.show(e.message);
      }
    },

    sendOTP() {
      const vm = this;
      if (vm.phone.length != 10) {
        vm.$alert.show("Please enter valid phone number");
        return;
      }
      var appVerifier = window.recaptchaVerifier;
      vm.loading = true;
      signInWithPhoneNumber(auth, `+91${vm.phone}`, appVerifier)
        .then((result) => {
          vm.loading = false;
          window.confirmationResult = result;
          console.log(window.confirmationResult);
          vm.isOtpSend = true;
        })
        .catch(function (e) {
          vm.loading = false;
          vm.$alert.show(e.message);
        });
    },
    initCaptcha() {
      this.loading = true;
      // let div = document.getElementById("recaptcha-container");
      // if (div) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function (response) {
            console.log(response);
          },
        },
        auth
      );
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
        this.loading = false;
      });
      // }
    },

    redirect() {
      let { redirect } = this.$route.query;
      this.$router.replace(redirect || "/");
    },
  },
  mounted() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.isLoggedIn = true;
        this.isAuthLoading = false;
        this.isNotSignedUp = true;
      } else {
        this.isAuthLoading = false;
        this.$nextTick(() => {
          this.initCaptcha();
        });
      }
    });
  },
};
</script>
