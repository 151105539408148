import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDk_Hq8i5sr_Jac38YnoptfnnaPLb2UnRo",
  authDomain: "around-a8790.firebaseapp.com",
  databaseURL:
    "https://around-a8790-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "around-a8790",
  storageBucket: "around-a8790.appspot.com",
  messagingSenderId: "849871907807",
  appId: "1:849871907807:web:8948a91903a12e7eda726a",
  measurementId: "G-84FVHLFJ2C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
const colPosts = collection(db, "userposts");
const colUsers = collection(db, "users");
const colGallery = collection(db, "gallery");
const storage = getStorage(app);
const functions = getFunctions(app);
if (location.hostname == "localhost") {
  connectFunctionsEmulator(functions, "localhost", "5001");
}
export { db, auth, colUsers, colPosts, storage, functions, colGallery };
