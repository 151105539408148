<template>
  <v-dialog v-model="dialog" max-width="300px" @click:outside="dialog = false">
    <v-card>
      <v-card-title>Message</v-card-title>
      <v-card-text v-html="message"></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="green" @click="dialog = false"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AlertModal from "./index";
export default {
  data() {
    return {
      message: "",
      dialog: false,
    };
  },
  methods: {
    show(message) {
      const vm = this;
      vm.message = message;
      vm.dialog = true;
    },
  },
  beforeMount() {
    AlertModal.EventBus.$on("show", (params) => {
      this.show(params);
    });
    AlertModal.EventBus.$on("hide", () => {
      this.dialog = false;
    });
  },
};
</script>

<style></style>
