import Vue from "vue";
import VueRouter from "vue-router";
import Invite from "../pages/invite/Invite.vue";
import Index from "../pages/public/Index.vue";
import People from "../pages/public/People.vue";
import Interests from "../pages/public/Interests.vue";
import Feed from "../pages/public/Feed.vue";
import InviteLogin from "../pages/invite/InviteLogin.vue";
import SignupComplete from "../pages/invite/SignupComplete.vue";
import PostView from "../pages/public/PostView.vue";
import Post from "../pages/public/Post.vue";
import flyRoutes from "./fly.routes.js";
import { isAuth, isNotAuth } from "@/assets/helper/auth.helper";
import Login from "../pages/Login.vue";
import HashtagPosts from "../pages/public/HashtagPosts.vue";
import Profile from "../pages/public/Profile.vue";
import websiteRoutes from "./website.routes";
Vue.use(VueRouter);

const routes = [
  ...flyRoutes,
  ...websiteRoutes,
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/people",
    name: "people",
    component: People,
  },
  {
    path: "/interests",
    name: "interests",
    component: Interests,
  },
  {
    path: "/skills",
    name: "skills",
    component: Interests,
  },
  {
    path: "/feed",
    name: "feed",
    component: Feed,
  },
  {
    path: "/post",
    name: "post",
    component: Post,
    beforeEnter: isAuth,
  },
  {
    path: "/post/:id",
    name: "post-view",
    component: PostView,
  },
  {
    path: "/invite/:id",
    name: "invite",
    component: Invite,
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: Profile,
  },
  {
    path: "/invite-login/:id",
    name: "invite-login",
    component: InviteLogin,
  },
  {
    path: "/signup-complete/:id",
    name: "signup-complete",
    component: SignupComplete,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: isNotAuth,
  },
  {
    path: "/signup",
    name: "signup",
    component: Login,
    beforeEnter: isNotAuth,
  },
  {
    path: "/hashtag/:hashtag",
    name: "hashtag-posts",
    component: HashtagPosts,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
