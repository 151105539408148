<template>
  <app-layout title="Profile">
    <div v-if="isLoading">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </div>
    <div v-else>
      <div class="text-center">
        <v-avatar color="secondary" size="120">
          <v-img v-if="user.photo_url" :src="user.photo_url" cover></v-img>
          <span v-else class="text-h5 text-uppercase">
            {{ getLogoName }}
          </span>
        </v-avatar>
        <div class="title mt-1">{{ user.display_name }}</div>
        <div
          class="subtitle-2 font-weight-regular black--text"
          v-if="user.Title"
        >
          {{ user.Title }}
        </div>
        <div class="subtitle-2 grey--text" v-if="location">
          <v-icon size="16" color="primary">mdi-map-marker</v-icon>
          {{ location }}
        </div>
      </div>
      <v-divider class="my-3" />
      <template v-if="user.interests && user.interests.length">
        <div class="subtitle-2 black--text">Interests</div>
        <div class="py-1">
          <v-chip
            v-for="(item, i) in user.interests"
            :key="i"
            outlined
            class="mr-1 mb-1 text-lowercase"
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
      </template>

      <template
        v-if="user.professionalskills && user.professionalskills.length"
      >
        <div class="subtitle-2 black--text">Pro Skills</div>
        <div class="py-1">
          <v-chip
            v-for="(item, i) in user.professionalskills"
            :key="i"
            class="mr-1 mb-1 text-lowercase"
            outlined
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
      </template>

      <v-divider
        class="my-3"
        v-if="user?.interests?.length && user?.professionalskills?.length"
      />
      <v-tabs v-model="tab" icons-and-text fixed-tabs>
        <v-tab>
          <div class="text-center">
            <div class="title font-weight-bold">
              {{ user.post_count || 0 }}
            </div>
            <div class="subtitle-2 text-none">Posts</div>
          </div>
        </v-tab>
        <v-tab>
          <div class="text-center">
            <div class="title font-weight-bold">
              {{ user.followers_count || 0 }}
            </div>
            <div class="subtitle-2 text-none">Followers</div>
          </div>
        </v-tab>
        <v-tab>
          <div class="text-center">
            <div class="title font-weight-bold">
              {{ user.following_count || 0 }}
            </div>
            <div class="subtitle-2 text-none">Following</div>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <profile-posts :userId="userId" :user="user" />
        </v-tab-item>
        <v-tab-item>
          <profile-people collection="followers" :user="user" />
        </v-tab-item>
        <v-tab-item>
          <profile-people collection="followings" :user="user" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import {
  doc,
  getCountFromServer,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { colPosts, colUsers } from "@/utils/firebase.utils";
import _ from "lodash";
import ProfilePosts from "@/components/public/ProfilePosts.vue";
import ProfilePeople from "@/components/public/ProfilePeople.vue";
export default {
  components: { AppLayout, ProfilePosts, ProfilePeople },
  data() {
    return {
      user: {},
      tab: 0,
      isLoading: true,
    };
  },
  computed: {
    getLogoName() {
      if (this.user && this.user.display_name) {
        return this.user.display_name.slice(0, 2);
      }
      return "A";
    },
    userId() {
      return this.$route.params.id;
    },
    location() {
      return _.get(this.user, "residential.Address", "");
    },
  },
  methods: {
    async init() {
      const vm = this;
      vm.isLoading = true;
      try {
        let userRef = doc(colUsers, vm.userId);
        vm.user = (await getDoc(userRef)).data();
        vm.user.id = vm.userId;
        let postCountQuery = query(colPosts, where("userref", "==", userRef));
        let postCountSnap = await getCountFromServer(postCountQuery);
        vm.user.post_count = postCountSnap.data().count;
        vm.isLoading = false;
      } catch (error) {
        vm.$router.replace("/");
        vm.handleError(error);
      }
    },
  },
  beforeMount() {
    this.init();
  },
};
</script>

<style></style>
