<template>
  <div
    class="py-1 pb-md-5 mx-auto"
    :class="fluid ? 'md:max-w-4xl mx-auto' : 'app-layout'"
  >
    <div class="d-flex align-center px-4 px-md-0">
      <div>
        <div class="d-flex align-center">
          <!-- <a @click="$router.push('/')">
            <img src="@/assets/img/logo.svg" style="width: 30px" />
          </a> -->
          <div class="title pt-1">{{ title || "around" }}</div>
        </div>
        <a
          class="d-flex align-center blue--text subtitle-2"
          v-if="!isLoginSignup"
          @click="openLocation"
        >
          <v-img
            contain
            max-width="15px"
            src="@/assets/img/logo-mini.png"
            class="mr-2"
          />
          {{ currentLocation.name || "Vasai" }}
        </a>
      </div>
      <v-spacer></v-spacer>
      <v-btn icon color="primary lighten-1" tile to="/post" v-if="showLocation">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <select-location v-if="showLocation" ref="location" />
      <profile-menu />
    </div>
    <div class="px-3 px-md-0 pt-3">
      <slot></slot>
    </div>
    <v-bottom-navigation app active-class="primary--text">
      <v-btn to="/">
        <span>Discover</span>
        <v-icon>mdi-compass-outline</v-icon>
      </v-btn>
      <v-btn to="/people">
        <span>People</span>
        <v-icon>mdi-account-group</v-icon>
      </v-btn>
      <v-btn to="/feed">
        <span>Feed</span>
        <v-icon>mdi-lightning-bolt</v-icon>
      </v-btn>
      <v-btn :to="uid ? `/profile/${uid}` : '/login'">
        <span>Account</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "@/utils/firebase.utils";
import ProfileMenu from "../public/ProfileMenu.vue";
import SelectLocation from "@/pages/public/SelectLocation.vue";
export default {
  components: { ProfileMenu, SelectLocation },
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    showLocation() {
      let skipRoutes = ["post", "signup", "login", "profile"];
      return skipRoutes.indexOf(this.$route.name) == -1;
    },
    isLoginSignup() {
      return ["signup", "login", "profile"].indexOf(this.$route.name) != -1;
    },
  },
  methods: {
    openLocation() {
      if (this.$refs.location) {
        this.$refs.location.dialog = true;
      }
    },
    logout() {
      this.$store.commit("SET_AUTH", false);
      signOut(auth);
    },
  },
};
</script>

<style lang="scss">
.app-layout {
  max-width: 500px;
}
</style>
