/* eslint-disable no-useless-escape */
const emailRe = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
export default {
  required: (v) => !!v || "Field is required",
  email: (v) => !v || emailRe.test(v) || "E-mail must be valid",
  phone: (v) => {
    return !v || v.length == 14 || "Please enter a valid US Phone number";
  },
  ssn: (v) => {
    return !v || v.length == 11 || "Please enter a valid SSN number";
  },
  zip: (v) => !v || v.length == 5 || "Please enter a valid zip code",
  password: (v) =>
    (v &&
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        v
      )) ||
    "At least 8 characters, should contain Letters, Numbers and Special Characters",
  multiselect: (v) => v.length > 0 || "Please select atleast one item",
  percentage: (v) =>
    (v <= 99 && v >= 1) || "Percentage should be between 1 to 99",
  commission_rate: (v) =>
    (v <= 100 && v >= 0) || "Percentage should be between 0 to 100",
  commission: (v) => v < 1 || "Commission rate must be less then 100%",
};
