<template>
  <app-layout title="People Around">
    <people-filter v-model="filter" @onFilter="onFilter($event)" />
    <v-card
      outlined
      rounded="lg"
      v-for="(user, i) in peoples"
      :key="i"
      class="mb-3"
      :to="`/profile/${user.id}`"
    >
      <v-list-item three-line>
        <v-list-item-avatar>
          <v-avatar color="secondary">
            <v-img :src="getUserProfileUrl(user)"></v-img>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1 text-capitalize">{{
            user.display_name
          }}</v-list-item-title>
          <v-list-item-subtitle class="caption black--text">
            {{ user.Title || "-" }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="caption">
            {{ getAddress(user) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            class="mt-2"
            v-if="user.isFollowing"
            color="primary"
            outlined
            rounded
            @click.prevent="unFollowUser(user)"
            :loading="user.loading"
            small
            >Following</v-btn
          >
          <v-btn
            @click.prevent="followUser(user)"
            class="primary mt-2"
            v-else
            rounded
            :loading="user.loading"
            small
            depressed
            >Follow</v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-card-text class="pt-0">
        <div class="subtitle-2 black--text">Interests</div>
        <div v-if="user.interests && user.interests.length" class="py-2">
          <v-chip
            v-for="(item, i) in user.interests"
            :key="i"
            outlined
            class="mr-1 mb-1 text-lowercase"
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
        <div v-else class="py-2">None Listed</div>
        <div class="subtitle-2 black--text">Pro Skills</div>
        <div
          v-if="user.professionalskills && user.professionalskills.length"
          class="py-2"
        >
          <v-chip
            v-for="(item, i) in user.professionalskills"
            :key="i"
            class="mr-1 mb-1 text-lowercase"
            outlined
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
        <div v-else class="py-2">None Listed</div>
      </v-card-text>
    </v-card>
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
  </app-layout>
</template>

<script>
import AppLayout from "@/components/ui/AppLayout.vue";
import { fetchAlgoliaUsers } from "@/utils/algolia.utils";
import { mapDocs } from "@/utils/common.utils";
import _ from "lodash";
import peopleMixins from "@/mixins/people.mixins";

import InfiniteLoading from "vue-infinite-loading";
import PeopleFilter from "@/components/public/PeopleFilter.vue";
export default {
  components: {
    AppLayout,
    InfiniteLoading,
    PeopleFilter,
  },
  mixins: [peopleMixins],
  data() {
    return {
      peoples: [],
      loading: true,
      search: "",
      filter: {
        interests: "",
        professionalskills: "",
      },
      page: 0,
    };
  },
  methods: {
    async infiniteHandler($state) {
      const vm = this;
      try {
        let { interests, professionalskills, search } = vm.filter;
        let query = search?.trim() || "*";
        let facetFilters = [];

        let requestBody = {
          page: vm.page,
          hitsPerPage: 10,
          query,
          index: "users",
          aroundLatLng: vm.currentLocation.latlong,
          aroundRadius: "25000",
        };
        if (interests && interests != "all") {
          facetFilters.push(`interests:${interests}`);
        }
        if (professionalskills && professionalskills != "all") {
          facetFilters.push(`professionalskills:${professionalskills}`);
        }
        if (facetFilters.length > 0) requestBody.facetFilters = facetFilters;
        let peopleRes = await fetchAlgoliaUsers({
          ...requestBody,
        });

        let peoples = mapDocs(peopleRes.docs).map((i) => {
          i.loading = false;
          return i;
        });

        peoples = await vm.getUsersFollow(peoples);
        vm.peoples = vm.peoples.concat(peoples);
        vm.page = parseInt(vm.page) + 1;
        if (peopleRes.docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.handleError(error);
      }
    },
    getAddress(user) {
      return _.get(user, "residential.Address", "");
    },
    onFilter(event) {
      this.filter = event;
      this.page = 0;
      this.$refs.loader.stateChanger.reset();
      this.peoples = [];
      this.$router.replace({
        name: "people",
        query: this.filter,
      });
    },
  },
  beforeMount() {
    let query = this.$route.query;
    Object.keys(this.filter).forEach((i) => {
      if (query[i]) {
        this.filter[i] = query[i];
      }
    });
  },
};
</script>

<style></style>
