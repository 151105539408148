import rulesConstants from "@/assets/constants/rules.constants";

const moment = require("moment");
export const getDate = () => {
  return moment().format("YYYY-MM-DDTHH:mm:ss");
};

export function getRules(rules) {
  if (!rules) return [];
  rules = rules.split("|");
  let list = [];
  rules.forEach((rule) => {
    if (rulesConstants[rule]) list.push(rulesConstants[rule]);
  });
  return list;
}

export const mapDocs = (docs) => {
  return docs.map((i) => {
    return { ...i.data(), id: i.id };
  });
};
export const mapFacets = (item) => {
  return Object.keys(item).map((facet) => {
    let value = item[facet];
    let name = facet;
    if (!name.startsWith("#")) name = `#${name}`;
    return { name, value, facet };
  });
};
export const mapFacetSelect = (item) => {
  return Object.keys(item).map((name) => {
    let formatedName = name?.charAt(0).toUpperCase() + name?.slice(1);
    return { name: `${formatedName} (${item[name]})`, value: name };
  });
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      reject(error);
    };
  });
}
