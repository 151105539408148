<template>
  <div>
    <v-divider class="mt-5"></v-divider>
    <div class="d-flex py-3 flex-column align-center">
      <v-img src="../../assets/img/logo.png" max-width="120px" contain></v-img>
      <div
        class="text-md-title text-subtitle-1 font-weight-medium mt-3 text-center"
        v-if="!isAuth"
      >
        Your superpower to discover & engage <br />with people around!
        <br />
        <v-btn outlined color="primary" to="/signup" class="mt-3"
          >Join Now</v-btn
        >
      </div>
    </div>
    <div class="d-flex justify-space-between mt-5">
      <a
        @click="$router.push('/privacy-policy')"
        class="text-md-subtitle-1 text-caption text-decoration-underline"
      >
        Privacy Policy
      </a>
      <a
        class="text-md-subtitle-1 text-caption text-decoration-underline"
        @click="$router.push('/cookie-policy')"
      >
        Cookie Policy
      </a>
      <a
        @click="$router.push('/terms')"
        class="text-md-subtitle-1 text-caption text-decoration-underline"
      >
        Term of use
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
