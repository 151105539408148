<template>
  <div>
    <v-card
      outlined
      rounded="lg"
      v-for="(user, i) in peoples"
      :key="i"
      class="mt-3"
    >
      <v-list-item three-line>
        <v-list-item-avatar>
          <v-avatar color="secondary">
            <v-img :src="getUserProfileUrl(user)"></v-img>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1 text-capitalize">{{
            user.display_name
          }}</v-list-item-title>
          <v-list-item-subtitle class="caption black--text">
            {{ user.Title || "-" }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="caption">
            {{ getAddress(user) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            class="mt-2"
            v-if="user.isFollowing"
            color="primary"
            outlined
            rounded
            @click="unFollowUser(user)"
            :loading="user.loading"
            small
            >Following</v-btn
          >
          <v-btn
            @click="followUser(user)"
            class="primary mt-2"
            v-else
            rounded
            :loading="user.loading"
            small
            depressed
            >Follow</v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-card-text class="pt-0">
        <div class="subtitle-2 black--text">Interests</div>
        <div v-if="user.interests && user.interests.length" class="py-2">
          <v-chip
            v-for="(item, i) in user.interests"
            :key="i"
            outlined
            class="mr-1 mb-1 text-lowercase"
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
        <div v-else class="py-2">None Listed</div>
        <div class="subtitle-2 black--text">Pro Skills</div>
        <div
          v-if="user.professionalskills && user.professionalskills.length"
          class="py-2"
        >
          <v-chip
            v-for="(item, i) in user.professionalskills"
            :key="i"
            class="mr-1 mb-1 text-lowercase"
            outlined
            color="primary"
            small
            >{{ item }}</v-chip
          >
        </div>
        <div v-else class="py-2">None Listed</div>
      </v-card-text>
    </v-card>
    <infinite-loading ref="loader" @infinite="infiniteHandler" :distance="430">
      <v-skeleton-loader
        slot="spinner"
        type="list-item-avatar-three-line, image, article"
      />
    </infinite-loading>
  </div>
</template>

<script>
import { mapDocs } from "@/utils/common.utils";
import _ from "lodash";
import peopleMixins from "@/mixins/people.mixins";

import InfiniteLoading from "vue-infinite-loading";
import {
  collection,
  doc,
  documentId,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { colUsers } from "@/utils/firebase.utils";
export default {
  props: {
    collection: {
      type: String,
      default: "followers",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    InfiniteLoading,
  },
  mixins: [peopleMixins],
  data() {
    return {
      peoples: [],
      loading: true,
      lastUser: null,
    };
  },
  methods: {
    async infiniteHandler($state) {
      const vm = this;
      try {
        let userRef = doc(colUsers, vm.user.id);
        let collectionRef = collection(userRef, this.collection);
        let conditions = [orderBy("created_at", "desc")];
        if (vm.lastUser) {
          conditions.push(startAfter(vm.lastUser));
        }
        conditions.push(limit(10));
        let userQuery = query(collectionRef, ...conditions);
        let docs = (await getDocs(userQuery)).docs;
        vm.lastUser = docs[docs.length - 1];
        let peopleIds = docs.map((i) => vm.getUserRefId(i.data().user_ref));
        if (peopleIds.length > 0) {
          let userDocQuery = query(
            colUsers,
            where(documentId(), "in", peopleIds)
          );
          let users = (await getDocs(userDocQuery)).docs;
          let peoples = mapDocs(users).map((i) => {
            i.loading = false;
            return i;
          });
          vm.peoples = vm.peoples.concat(peoples);
        }
        if (docs.length < 10) {
          $state.complete();
        } else {
          $state.loaded();
        }
      } catch (error) {
        $state.complete();
        vm.handleError(error);
      }
    },
    getAddress(user) {
      return _.get(user, "residential.Address", "");
    },
  },
};
</script>

<style></style>
