<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn icon color="primary lighten-1" v-on="on" tile>
        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
    </template>
    <dialog-card
      :isSubmit="false"
      title="Select Location"
      @close="dialog = false"
    >
      <address-field
        hide-details
        @onAddress="onAddress"
        label="Search Location"
      ></address-field>
      <div class="text-center my-2">or</div>
      <v-btn
        outlined
        block
        :loading="loading"
        @click="useCurrent()"
        color="primary"
        class="mb-5"
        >Use Current Location</v-btn
      >
    </dialog-card>
  </v-dialog>
</template>

<script>
import DialogCard from "@/components/ui/DialogCard.vue";
import AddressField from "@/components/ui/form/AddressField.vue";
import axios from "axios";
import { MAP_API_KEY } from "@/assets/constants/config.constants";
export default {
  components: { DialogCard, AddressField },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    onAddress(e) {
      if (e) {
        this.$store.commit("SET_CURRENT_LOCATION", {
          name: e.name,
          latlong: `${e.latitude}, ${e.longitude}`,
        });
        this.dialog = false;
      } else {
        this.form.postLocation = undefined;
      }
    },
    useCurrent() {
      const vm = this;
      vm.loading = true;
      if ("geolocation" in navigator) {
        // Get the user's current position
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            let apiKey = MAP_API_KEY;
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
            axios
              .get(apiUrl)
              .then((res) => {
                let results = res.data.results;
                vm.loading = false;
                if (results.length == 0) {
                  vm.$alert("Nothing found here!");
                  return;
                }
                let subLocality = results[0].address_components.find(
                  (i) => i.types.indexOf("sublocality") != -1
                );
                let locality = results[0].address_components.find(
                  (i) => i.types.indexOf("locality") != -1
                );
                let name = "";
                if (subLocality) {
                  name = subLocality.short_name;
                } else if (locality) {
                  name = locality.short_name;
                }
                if (name) {
                  vm.$store.commit("SET_CURRENT_LOCATION", {
                    name,
                    latlong: `${latitude}, ${longitude}`,
                  });
                  vm.dialog = false;
                  return;
                }

                vm.$alert.show("Nothing found here!");
              })
              .catch((e) => {
                vm.handleError(e);
              });

            // Now you can use the latitude and longitude values as needed
          },
          function (error) {
            vm.loading = false;
            vm.$alert.show("Error getting location:" + error.message);
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        vm.loading = false;
        vm.$alert.show("Geolocation is not supported by this browser.");
        console.log("Geolocation is not supported by this browser.");
      }
    },
  },
};
</script>

<style></style>
