import {
  collection,
  doc,
  getDocs,
  increment,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { colUsers, db } from "@/utils/firebase.utils";
import { mapDocs } from "@/utils/common.utils";

export default {
  methods: {
    async followUser(user) {
      const vm = this;
      if (!vm.confirmLogin()) return;
      try {
        user.loading = true;
        let authUserRef = doc(colUsers, vm.uid);
        let userRef = doc(colUsers, user.id);
        let followingRef = collection(colUsers, vm.uid, "followings");
        let followerRef = collection(colUsers, user.id, "followers");
        let followingQuery = query(
          followingRef,
          where("user_ref", "==", userRef)
        );
        let followings = mapDocs((await getDocs(followingQuery)).docs);
        let followerQuery = query(
          followerRef,
          where("user_ref", "==", authUserRef)
        );
        let followers = mapDocs((await getDocs(followerQuery)).docs);
        let batch = writeBatch(db);
        if (followings.length == 0) {
          batch.set(doc(followingRef, doc(followingRef).id), {
            created_at: new Date(),
            user_ref: userRef,
          });
          batch.update(authUserRef, {
            following_count: increment(1),
          });
        }
        if (followers.length == 0) {
          batch.set(doc(followerRef, doc(followerRef).id), {
            created_at: new Date(),
            user_ref: authUserRef,
          });
          batch.update(userRef, {
            followers_count: increment(1),
          });
        }
        await batch.commit();
        user.loading = false;
        user.isFollowing = true;
      } catch (error) {
        user.loading = false;
        vm.handleError(error);
      }
    },
    async unFollowUser(user) {
      const vm = this;
      if (!vm.confirmLogin()) return;
      try {
        user.loading = true;
        let authUserRef = doc(colUsers, vm.uid);
        let userRef = doc(colUsers, user.id);
        let followingRef = collection(colUsers, vm.uid, "followings");
        let followerRef = collection(colUsers, user.id, "followers");
        let followingQuery = query(
          followingRef,
          where("user_ref", "==", userRef)
        );
        let followings = (await getDocs(followingQuery)).docs;
        let followerQuery = query(
          followerRef,
          where("user_ref", "==", authUserRef)
        );
        let followers = (await getDocs(followerQuery)).docs;
        let batch = writeBatch(db);
        if (followings.length != 0) {
          followings.forEach((i) => {
            batch.delete(i.ref);
          });
          batch.update(authUserRef, {
            following_count: increment(-1),
          });
        }
        if (followers.length != 0) {
          followers.forEach((i) => {
            batch.delete(i.ref);
          });
          batch.update(userRef, {
            followers_count: increment(-1),
          });
        }
        await batch.commit();
        user.loading = false;
        user.isFollowing = false;
      } catch (error) {
        user.loading = false;
        vm.handleError(error);
      }
    },
  },
};
