<template>
  <v-app>
    <app-header v-if="isAppHeader"></app-header>
    <v-main>
      <router-view />
    </v-main>
    <Confirm></Confirm>
    <Alert></Alert>
    <SnackBar></SnackBar>
  </v-app>
</template>

<script>
import { onAuthStateChanged } from "firebase/auth";
import AppHeader from "./components/AppHeader.vue";
import Alert from "./components/ui/alert/Alert.vue";
import Confirm from "./components/ui/confirm/Confirm.vue";
import SnackBar from "./components/ui/snackbar/SnackBar.vue";

import { auth, colUsers } from "./utils/firebase.utils";
import { doc, onSnapshot } from "firebase/firestore";
export default {
  name: "App",
  components: {
    Alert,
    AppHeader,
    Confirm,
    SnackBar,
  },
  computed: {
    isAppHeader() {
      return this.$route.meta.isFly == true;
    },
  },
  data: () => ({
    unsubscribe: null,
  }),
  mounted() {
    onAuthStateChanged(auth, (user) => {
      this.$store.commit("SET_AUTH", user != null);
      if (user) {
        this.unsubscribe = onSnapshot(doc(colUsers, user.uid), (snap) => {
          this.$store.commit("SET_USER", snap.data() || {});
        });
      }
    });
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe();
  },
  beforeMount() {
    this.$store.dispatch("setCurrentLocation");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reset.scss";
.border-bottom {
  border-bottom: 1px solid #e1e3e6 !important;
}
.app-header .v-toolbar__content {
  @extend .border-bottom;
}
</style>
